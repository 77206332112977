import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CsrfProvider from "@repo/shared/components/CsrfProvider/index.ts";
import SessionProvider from "@repo/shared/components/SessionProvider/index.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import { ConfirmProvider } from "react-confirm-hook";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import FullPageSpinner from "./components/FullPageSpinner/index.ts";
import MultiProvider from "./components/MultiProvider/index.ts";
import { router } from "./router.js";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Root element missing");
}

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#d35015",
        },
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});
const root = createRoot(container);

root.render(
    <StrictMode>
        <MultiProvider
            providerCreators={[
                (children) => (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                ),
                (children) => (
                    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
                ),
                (children) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {children}
                    </LocalizationProvider>
                ),
                (children) => <SnackbarProvider>{children}</SnackbarProvider>,
                (children) => <ConfirmProvider>{children}</ConfirmProvider>,
                (children) => <CsrfProvider>{children}</CsrfProvider>,
                (children) => (
                    <SessionProvider identityUiBaseUrl={import.meta.env.VITE_APP_IDENTITY_URL}>
                        {children}
                    </SessionProvider>
                ),
            ]}
        >
            <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />
        </MultiProvider>
    </StrictMode>,
);
