import Layout from "@repo/shared/components/Layout/index.ts";
import { lazy } from "react";
import { Outlet, type RouteObject, createBrowserRouter } from "react-router-dom";
import RootErrorBoundary from "./components/RootErrorBoundary/index.ts";
import AppPage from "./pages/AppPage/index.ts";
import HomePage from "./pages/HomePage/index.ts";

const IdentityApp = lazy(() => import("@repo/identity/App.tsx"));
const HrApp = lazy(() => import("@repo/hr/App.tsx"));
const LogisticsApp = lazy(() => import("@repo/logistics/App.tsx"));
const AccountingApp = lazy(() => import("@repo/accounting/App.tsx"));
const RegistrationApp = lazy(() => import("@repo/registration/App.tsx"));
const ShiftApp = lazy(() => import("@repo/shift/App.tsx"));
const ReceptionApp = lazy(() => import("@repo/reception/App.tsx"));

const pathRoutes: RouteObject[] = [
    {
        path: "/",
        element: <Layout title="Manage Dashboard" />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
        ],
    },
    {
        path: "/identity/*",
        element: <AppPage App={IdentityApp} />,
    },
    {
        path: "/hr/*",
        element: <AppPage App={HrApp} />,
    },
    {
        path: "/logistics/*",
        element: <AppPage App={LogisticsApp} />,
    },
    {
        path: "/accounting/*",
        element: <AppPage App={AccountingApp} />,
    },
    {
        path: "/registration/*",
        element: <AppPage App={RegistrationApp} />,
    },
    {
        path: "/shift/*",
        element: <AppPage App={ShiftApp} />,
    },
    {
        path: "/reception/*",
        element: <AppPage App={ReceptionApp} />,
    },
];

const rootRoute: RouteObject = {
    path: "/",
    element: <Outlet />,
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

export const router = createBrowserRouter([rootRoute]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
